import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const NotFoundPage = () => {
  return (
    <div>
      <Helmet>
        <title>404 Not Found</title>
        <meta property="og:title" content="404 Not Found" />
        <meta property="og:description" content="404 Not Found" />
        <meta name="description" content="404 Not Found" />
        <meta
          property="og:image"
          content="https://eddisrupt.com/images/zoomcall3.png"
        />
        <meta property="og:image:width" content="1500" />
        <meta property="og:image:height" content="945" />
        <meta property="og:url" content="https://eddisrupt.com/press" />
        <meta name="twitter:title" content="404 Not Found" />
        <meta name="twitter:description" content="404 Not Found" />
        <meta
          name="twitter:image"
          content="https://eddisrupt.com/images/zoomcall3.png"
        />
        <meta name="twitter:card" content="404 Not Found" />
      </Helmet>

      <div className="utility-page-wrap bg-primary-3">
        <div className="utility-page-content w-form">
          <h2 className="display-heading-2">Page not found</h2>
          <div className="large-text space-bottom">
            The page you are looking for no longer exists or has been moved.
            Contact us if you think this is an error, thank you.
          </div>
          <Link to="/" className="button button-large w-button">
            Go to website
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
